* {
  margin: 0;
  padding: 0;
}

.gradient-line::after {
  content: "";
  display: block;
  background: linear-gradient(to right, #4d9cdb, #2974fa);
  width: 10px;
  height: 30px;
  position: absolute;
  border-radius: 10px;
  left: 50%;
}

.video-container {
  position: fixed;
  overflow: hidden;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: -1;
  filter: brightness(80%) saturate(90%) hue-rotate(20deg);
}


.video-background {
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}


.bg1 {
  /* background-image: url('./assets/bg_1.webp'); */
  margin-top: 70px;
  /* background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover; */
}

.bg2 {
  background-image: url('./assets/bg_2.webp');
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.bg3 {
  background-image: url('./assets/bg_3.jpg');
  background-attachment: fixed;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
}

.navLinks {
  color: #1F305E;
  cursor: pointer;
  font-weight: bolder;
  position: relative;
}

.navLinks::after {
  content: '';
  position: absolute;
  background-color: #1F305E;
  height: 1.5px;
  width: 105%;
  top: 100%;
  left: 0;
  transition: width 0.2s ease-in-out;
  width: 0;
}

.navLinks:hover::after {
  width: 105%;
}